<app-customer-signature>


  <div class="CustomerSignatureContractsList Page">

    <div class="CustomerSignatureDocDetails Page">

      <div class="CustomerSignatureDocDetails-toolbar">
        <div class="CustomerSignatureContractsList-text">
          <h1>Lista de documentos para assinar.</h1>
          <p>Clique em um documento para ver mais detalhes</p>
        </div>
        <button mat-raised-button color="accent" (click)="signDocuments()">
          Assinar
        </button>
      </div>
    </div>


    <mat-card class="example-card" appearance="outlined">
      <mat-card-header>
        <mat-card-title>Contrato de Financiamento</mat-card-title>
        <mat-card-subtitle>Número {{ routeState.mainDocInfo.document.fileId }}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content class="example-card-content">
        <p class="example-card-content-p"><strong>Nome do
          cliente:</strong> {{ routeState.mainDocInfo.proposal.customerName }}</p>
        <p class="example-card-content-p"><strong>Local da compra: </strong>{{ routeState.mainDocInfo.proposal.store }}
          -  {{ routeState.mainDocInfo.proposal.storeName }}
          em {{ routeState.mainDocInfo.proposal.proposalDate | date: 'dd/MM/yyyy HH:mm' }}</p>
        <p class="example-card-content-p"><strong>Produto: </strong> {{ routeState.mainDocInfo.proposal.product }} </p>
        <p class="example-card-content-p">
          <strong>Financeiro: </strong> {{ routeState.mainDocInfo.proposal.installmentQty }}
          x {{ routeState.mainDocInfo.proposal.financedValue }}, 1º vencimento
          em {{ routeState.mainDocInfo.proposal.installmentFirstDue }}</p>


      </mat-card-content>

    </mat-card>
    <br>


    <mat-card class="CustomerSignatureContractsList-card">

      <mat-nav-list>

        <a class="CustomerSignatureContractsList-listItem" mat-list-item (click)="openDocDetails('Main')"
           *ngIf="routeState != null">
          <p matLine class="CustomerSignatureContractsList-itemSignaturesFileName">
            <i class="icofont-file-document"></i>
            <strong>{{ routeState.mainDocInfo.document.documentFileName }}</strong>
          </p>
          <p class="CustomerSignatureContractsList-itemSignaturesCounter" matLine>
            Assinatura expira em {{ routeState.mainDocInfo.document.expiration | date:'short' }}
          </p>

          <i class="CustomerSignatureContractsList-icon--rightArrow icofont-rounded-right"></i>
        </a>

        <div *ngIf="routeState != null">
          <a class="CustomerSignatureContractsList-listItem" mat-list-item
             (click)="openDocDetails('Additional', item.documentAdditionalGuid)"
             *ngFor="let item of routeState.mainDocInfo.documentsAdditional">
            <p matLine class="CustomerSignatureContractsList-itemSignaturesFileName">
              <i class="icofont-file-document"></i>
              <strong>{{ item.documentAdditionalFileName }}</strong>
            </p>
            <p class="CustomerSignatureContractsList-itemSignaturesCounter" matLine>
              Assinatura expira em {{ routeState.mainDocInfo.document.expiration | date:'short' }}
            </p>

            <i class="CustomerSignatureContractsList-icon--rightArrow icofont-rounded-right"></i>
          </a>
        </div>
      </mat-nav-list>

    </mat-card>

    <button class="CustomerSignatureContractsList-button--signAllContracts" mat-raised-button color="accent"
            (click)="signDocuments()"><B>ASSINAR DOCUMENTOS</B>
    </button>

  </div>

  <app-loader [isLoading]="isLoading"></app-loader>
</app-customer-signature>
