import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-signature',
  templateUrl: './customer-signature.component.html',
  styleUrls: ['./customer-signature.component.scss']
})
export class CustomerSignatureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
