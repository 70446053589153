import {Component, Inject, OnInit} from '@angular/core';
import {ISigners} from "../../../definitions";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";

@Component({
  selector: 'app-more-info-bottom-sheet',
  templateUrl: './more-info-bottom-sheet.component.html',
  styleUrls: ['./more-info-bottom-sheet.component.scss']
})
export class MoreInfoBottomSheetComponent implements OnInit {

  public signers: ISigners[] = [];

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: ISigners[]
  ) {
    this.signers = data;
  }

  ngOnInit(): void {
  }

}
