import { Injectable } from '@angular/core';
import {SafeResourceUrl} from "@angular/platform-browser";

@Injectable({
  providedIn: 'root'
})
export class ClickDownloadService {

  constructor() { }

  run(src: string, mime: string) {
    const element = document.createElement('a');
    element.setAttribute('href', src);
    element.setAttribute('download', mime);

    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
}
