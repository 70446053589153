import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-signature-finished',
  templateUrl: './customer-signature-finished.component.html',
  styleUrls: ['./customer-signature-finished.component.scss']
})
export class CustomerSignatureFinishedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
