import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GearLoadingComponent } from './gear-loading.component';



@NgModule({
  declarations: [
    GearLoadingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [GearLoadingComponent]
})
export class GearLoaderModule { }
