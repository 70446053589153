import {Component, OnDestroy, OnInit} from '@angular/core';
// import {ICustomerSignatureRouteParams} from "../customer-signature-contract-sign/definitions";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {IValidateSignToken} from "../../../shared/dialogs/validate-phone-dialog/definitions";
import {
  ValidateSignTokenDialog
} from "../../../shared/dialogs/validate-phone-dialog/validate-sign-token-dialog.component";
import {Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ICustomerSignatureInfoValidationCpfReturn} from "../definitions";
import {IContractListRouteParam} from "./definitions";
import {environment} from "../../../../environments/environment";
import {catchError} from "rxjs/operators";
import {IHttpResponseGeneric} from "../../../shared/interfaces/i-http-response-generic";
import {HttpClient} from "@angular/common/http";
import {HttpUtilsService} from "../../../services/utils/http-utils.service";
import {MyHttpResponseErrorService} from "../../../services/http-response-error/my-http-response-error.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ImageUtilsService} from "../../../services/utils/image-utils.service";
import {ClickDownloadService} from "../../../services/utils/click-download.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-customer-signature-contract-list',
  templateUrl: './customer-signature-contract-list.component.html',
  styleUrls: ['./customer-signature-contract-list.component.scss']
})
export class CustomerSignatureContractListComponent implements OnInit, OnDestroy {

  public routeState!: IContractListRouteParam;
  private observableArray: Array<Subscription> = [];
  public isLoading: boolean = false;

  public guid: string | null = null;
  public key: string | null = null;
  public cpf: string | null = null;
  public uniqueKey: string | null = null;
  public token: string | null = null;

  private isMobile: boolean = window.innerWidth < environment.isMobile;

  constructor(
    private clickDownload: ClickDownloadService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private myHttpRespErr: MyHttpResponseErrorService,
    private snackBar: MatSnackBar,
    private sanitize: DomSanitizer,
  ) {
    this.routeState = this.router.getCurrentNavigation()?.extras.state as IContractListRouteParam;
  }

  ngOnInit(): void {

    if (!this.routeState) {
      this.observableArray.push(
        this.activatedRoute.paramMap.subscribe((value: ParamMap) => {
          this.guid = value.get('guid');
          this.key = value.get('key');
          this.cpf = value.get('cpf');
          this.uniqueKey = value.get('uniqueKey')
          this.token = value.get('token')


          if (this.cpf == null) {
            this.router.navigate(['/start-sign', value.get('guid'), value.get('key') ?? '']);

            return;
          }

          let postData;

          const link = this.uniqueKey === '' || this.uniqueKey === null ? 'PreSign' : 'PreSignKey';


          if (link === 'PreSign') {
            postData = {
              DocumentGuid: this.guid,
              DocumentKey: this.key,
              //key: this.routeParams.uniqueKey,
              DocumentSigner: this.cpf
            };
          } else {
            postData = {
              // DocumentGuid: this.routeParams.guid,
              // DocumentKey: this.routeParams.key,
              key: this.uniqueKey,
              DocumentSigner: this.cpf
            };
          }


          this.isLoading = true;

          this.observableArray.push(
            this.http.post(`${environment.baseUrl}/Documents/${link}`, postData, {
              headers: this.httpUtils.AddBasicHeaders()
            })
              .pipe(
                catchError((err) => this.myHttpRespErr.httpError(err, () => this.isLoading = false))
              )
              .subscribe(resp => {
                this.isLoading = false;

                if (resp === null) {
                  this.snackBar.open('Não existem documentos para assinar neste Cpf.', "OK");

                  return;
                }

                let data: IHttpResponseGeneric<ICustomerSignatureInfoValidationCpfReturn> =
                  resp as IHttpResponseGeneric<ICustomerSignatureInfoValidationCpfReturn>;

                this.routeState = {
                  key: this.key!,
                  cpf: this.cpf!,
                  mainDocInfo: data.data,
                  token: '',
                  uniqueKey: this.uniqueKey!,
                }
              })
          );
        })
      );
    }
  }

  public ngOnDestroy(): void {
    for (let i = 0; i < this.observableArray.length; ++i) {
      this.observableArray[i].unsubscribe();
    }
  }

  signDocuments() {
    // if (!this.routeState) return;

    const dialogData: IValidateSignToken = {
      guid: this.routeState.mainDocInfo.document.documentGuid!,
      key: this.routeState.mainDocInfo.document.documentKey!,
      signerCpf: this.routeState.cpf!,
      token: this.routeState.token!
    }

    this.observableArray.push(
      this.dialog.open(ValidateSignTokenDialog, {
        data: dialogData,
        closeOnNavigation: false,
        disableClose: true
      }).afterClosed().subscribe((isFinished: boolean) => {
        if (!isFinished) {
          return;
        }

        this.router.navigate(['/end-sign']);
      })
    );
  }

  getRouteInfoGetDocBin(type: 'Main' | 'Additional', additionalGuid: string = '') {
    return {
      DocumentMainLoginGuid: this.routeState.mainDocInfo.document.loginGuid,
      DocumentMainGuid: this.routeState.mainDocInfo.document.documentGuid,
      DocumentMainKey: this.routeState.mainDocInfo.document.documentKey,
      DocumentType: type,
      DocumentAdditionalGuid: additionalGuid,

    };
  }

  openDocDetails(type: 'Main' | 'Additional', additionalGuid: string = '') {
    if (this.isMobile) {
      this.loadPdf(type, additionalGuid);

      return;
    }

    let signersCounter = 0;
    this.routeState.mainDocInfo.signers.forEach(value => value.signed === 1 ? signersCounter++ : '');

    this.router.navigate(['/document-details', this.routeState.mainDocInfo.document.documentGuid, this.routeState.key,
      this.routeState.cpf!, this.routeState.uniqueKey], {
      state: {
        docInfo: this.getRouteInfoGetDocBin(type, additionalGuid),
        signersInfo: this.routeState.mainDocInfo.signers
      }
    });
  }

  private loadPdf(type: 'Main' | 'Additional', additionalGuid: string = '') {

    this.isLoading = true;

    this.observableArray.push(
      this.http.post(`${environment.baseUrl}/Documents/ViewBinary`, this.getRouteInfoGetDocBin(type, additionalGuid), {
        headers: this.httpUtils.AddBasicHeaders()
      })
        .pipe(
          catchError((err) => this.myHttpRespErr.httpError(err, () => this.isLoading = false))
        )
        .subscribe(resp => {
          this.isLoading = false;

          if (resp === null) {
            this.snackBar.open('Não existem documentos para assinar neste Cpf.', "OK");

            return;
          }

          let data: IHttpResponseGeneric<string> = resp as IHttpResponseGeneric<string>;

          // let pdfSan = this.sanitize.bypassSecurityTrustResourceUrl(data.data);
          const byteCharacters = atob(data.data.split(',')[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: 'application/pdf'});

          this.clickDownload.run(URL.createObjectURL(blob), 'application/pdf');
        })
    );
  }
}
