<div class="MoreInfo">
  <h2 class="MoreInfo-title">Assinantes</h2>

  <mat-list class="MoreInfo-signersList">
    <div *ngFor="let signer of signers">
      <mat-list-item class="MoreInfo-signersListItem" >
        <mat-icon mat-list-icon matTooltip="Assinado" class="green" *ngIf="signer.signed > 0">done</mat-icon>
        <mat-icon mat-list-icon matTooltip="Não Assinado" class="orange" *ngIf="signer.signed < 1">close</mat-icon>

        <div mat-line><strong>{{signer.fullname}}</strong></div>

        <div mat-line *ngIf="signer.signed > 0">Assinado em {{signer.sendKeyAuthSignAt | date:'short'}}</div>
        <div mat-line *ngIf="signer.signed < 1">Não assinado</div>
      </mat-list-item>

      <mat-divider></mat-divider>
    </div>

  </mat-list>
</div>
