import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringUtilsService {

  constructor() {
  }

  public addTelMask(tel: string): string {
    if (!tel) {
      return '';
    }

    let telClean = tel.trim().replace(/[ -/\\]/g, '');

    if (telClean[0] === '0') {
      telClean = telClean.substr(1);
    }

    let newTel = '(';
    newTel += telClean.substr(0, 2);
    newTel += ') ';

    let index = 2;
    if (telClean.length > 10) {
      newTel += telClean.substr(index, 1);
      newTel += ' ';

      index++;
    }

    newTel += telClean.substr(index, 4);
    newTel += '-';

    index += 4;
    newTel += telClean.substr(index, 4);

    return newTel;
  }

  public removeCpfMask(cpf: string): string {
    if (!cpf) {
      return '';
    }

    return cpf.replace(/[ .\-]/g, '');
  }

  public addCpfMask(cpf: string): string {
    if (!cpf) {
      return '';
    }

    const cpfClean = cpf.trim().replace(/[ -/\\]/g, '');

    let newCpf = cpfClean.substr(0, 3);
    newCpf += '.';
    newCpf += cpfClean.substr(3, 3);
    newCpf += '.';
    newCpf += cpfClean.substr(6, 3);
    newCpf += '-';
    newCpf += cpfClean.substr(9, 2);

    return newCpf;
  }

  public removeRgMask(rg: string): string {
    if (!rg) {
      return '';
    }

    return rg.replace(/[ .-]/g, '');
  }

  public isCpfValid(cpfStr: string) : boolean {
    if (!cpfStr) {
      return false;
    }

    cpfStr = cpfStr.replace(/[. \-]/g, '');

    let Soma;
    let Resto;
    Soma = 0;

    if (cpfStr === '00000000000') {
      return false;
    }

    for (let i = 1; i <= 9; i++) {
      Soma = Soma + parseInt(cpfStr.substring(i - 1, i), 10) * (11 - i);
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    if (Resto !== parseInt(cpfStr.substring(9, 10), 10)) {
      return false;
    }

    Soma = 0;
    for (let i = 1; i <= 10; i++) {
      Soma = Soma + parseInt(cpfStr.substring(i - 1, i), 10) * (12 - i);
    }

    Resto = (Soma * 10) % 11;
    if ((Resto === 10) || (Resto === 11)) {
      Resto = 0;
    }

    return Resto === parseInt(cpfStr.substring(10, 11), 10);
  }

  public isCellphoneValid(tel: string) : boolean {
    if (!tel) {
      return false;
    }

    const index = 2;
    return tel[index] === '9' || tel[index] === '8' || tel[index] === '7' || tel[index] === '6';
  }

  public isTelephoneValid(str: string) : boolean {
    if (!str) {
      return false;
    }

    const newStr = str.replace(/\.- \(\)/g, '');

    return newStr.length > 10;
  }

  public addCnpjMask(cnpj:string):string {
    return cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1 $2 $3/$4-$5").split(' ').join('.');
  }
}
