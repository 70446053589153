<app-customer-signature>
  <div class="CustomerSignatureDocDetails Page">

    <div class="CustomerSignatureDocDetails-toolbar">
      <a mat-raised-button color="primary" [routerLink]="['/sign-list', guid, key, cpf, uniquekey]">
        <i class="icofont-simple-left"></i>
        Voltar
      </a>

      <h1>Visualização do Documento</h1>

      <button mat-raised-button color="accent" *ngIf="this.routeState != null" (click)="moreInfo()">
        <i class="icofont-users-alt-2"></i>
        Assinaturas
      </button>
    </div>

    <div class="CustomerSignatureDocDetails-pdfWrapper" *ngIf="pdfStr != null">
      <object [data]="pdfStr" type="application/pdf">
        <iframe [src]="pdfStr">Este navegador não suporta PDF!</iframe>
      </object>
    </div>

  </div>

  <app-loader [isLoading]="isLoading"></app-loader>
</app-customer-signature>
