import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {registerLocaleData} from "@angular/common";
import localePt from '@angular/common/locales/pt';
import localeExtra from '@angular/common/locales/extra/pt';
import {getBrPaginatorIntl} from "./shared/intl/func-paginator";
import {ValidateSignTokenDialogModule} from "./shared/dialogs/validate-phone-dialog/validate-sign-token-dialog.module";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

registerLocaleData(localePt, 'pt', localeExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ValidateSignTokenDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE, useValue: 'pt-BR',
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL'
    },
    {
      provide: LOCALE_ID, useValue: 'pt-BR'
    },
    {
      provide: MatPaginatorIntl, useValue: getBrPaginatorIntl()
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
