import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment'; // Importa o arquivo de ambiente

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Propriedades que serão usadas dinamicamente
  logoPath: string = environment.logoPath;
  toolbarColor: string = environment.toolbarColor;

  constructor() { }

  ngOnInit(): void {
    // Lógica adicional, se necessário
  }
}
