import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpUtilsService} from "../../../services/utils/http-utils.service";
import {CpfMask} from "../../../shared/constants/masks";
import {environment} from "../../../../environments/environment";
import {MyHttpResponseErrorService} from "../../../services/http-response-error/my-http-response-error.service";
import {catchError} from "rxjs/operators";
import {IHttpResponseGeneric} from "../../../shared/interfaces/i-http-response-generic";
import {ICustomerSignatureInfoValidationCpfReturn} from "../definitions";
import {ICustomerSignatureRouteParams} from "./definitions";
import {StringUtilsService} from "../../../services/utils/string-utils.service";

@Component({
  selector: 'app-customer-signature-info-validation',
  templateUrl: './customer-signature-info-validation.component.html',
  styleUrls: ['./customer-signature-info-validation.component.scss']
})
export class CustomerSignatureInfoValidationComponent implements OnInit, OnDestroy {
  public validationForm!: FormGroup;
  public isLoading: boolean = false;
  public cpfMask = CpfMask;

  private observableArray: Array<Subscription> = [];
  private routeParams!: ICustomerSignatureRouteParams;

  constructor(
    private stringUtils: StringUtilsService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private myHttpRespErr: MyHttpResponseErrorService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {
    this.observableArray.push(
      activatedRoute.paramMap.subscribe((value: ParamMap) => {
        this.routeParams = {
          guid: value.get('guid') ?? '',
          key: value.get('key') ?? '',
          token: value.get('token') ?? '',
          uniqueKey: value.get('uniqueKey') ?? ''
        }
      })
    );
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public ngOnDestroy(): void {
    for (let i = 0; i < this.observableArray.length; ++i) {
      this.observableArray[i].unsubscribe();
    }
  }

  public submitForm() {
    if (this.validationForm.invalid) {
      return;
    }

    if (!this.stringUtils.isCpfValid(this.validationForm.get('Cpf')?.value)) {
      this.snackBar.open('Cpf inválido.', 'OK', {
        duration: 2500
      });

      return;
    }

    try {
      this.isLoading = true;


      const cpf = this.validationForm.get('Cpf')?.value.replace(/[.-]/g, '');

      let postData;

      const link = this.routeParams.uniqueKey === '' || this.routeParams.uniqueKey === null ? 'PreSign' : 'PreSignKey';


      if (link === 'PreSign') {
        postData = {
          DocumentGuid: this.routeParams.guid,
          DocumentKey: this.routeParams.key,
          //key: this.routeParams.uniqueKey,
          DocumentSigner: this.validationForm.get('Cpf')?.value.replace(/[.-]/g, '')
        };
      } else {
        postData = {
          // DocumentGuid: this.routeParams.guid,
          // DocumentKey: this.routeParams.key,
          key: this.routeParams.uniqueKey,
          DocumentSigner: this.validationForm.get('Cpf')?.value.replace(/[.-]/g, '')
        };
      }


      this.observableArray.push(
        this.http.post(`${environment.baseUrl}/Documents/${link}`, postData, {
          headers: this.httpUtils.AddBasicHeaders()
        })
          .pipe(
            catchError((err) => this.myHttpRespErr.httpError(err, () => this.isLoading = false))
          )
          .subscribe(resp => {
            this.isLoading = false;

            if (resp === null) {
              this.snackBar.open('Não existem documentos para assinar neste Cpf.', "OK");

              return;
            }

            let data: IHttpResponseGeneric<ICustomerSignatureInfoValidationCpfReturn> =
              resp as IHttpResponseGeneric<ICustomerSignatureInfoValidationCpfReturn>;

            this.router.navigate(['/sign-list', data.data.document.documentGuid, data.data.document.documentKey, cpf, this.routeParams.uniqueKey, this.routeParams.token], {
              state: {
                key: data.data.document.documentKey,
                mainDocInfo: data.data,
                cpf: cpf,
                token: this.routeParams.token,
                uniqueKey: this.routeParams.uniqueKey
              }
            });
          })
      );
    } catch (e) {
      this.isLoading = false;

      console.error(e);
    }

  }

  private initForm() {
    this.validationForm = this.formBuilder.group({
      Cpf: ['', [Validators.required]],
    });
  }
}
