import { Injectable } from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {EMPTY, Observable} from "rxjs";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IHttpResponseErrorData} from "../../shared/interfaces/i-http-response-erro-data";
import {Route, Router} from "@angular/router";

@Injectable({
  providedIn: 'root',
})
export class MyHttpResponseErrorService {

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
  ) { }

  httpError(err: HttpErrorResponse, callback: () => void) : Observable<never> {
    callback();

    // if(err.status == 401) {
    //   this.snackBar.open('É necessário fazer login novamente.', 'ok', {
    //     panelClass: ['MySnackBar', 'MySnackBar--error'],
    //   });
    //
    //   return EMPTY;
    // }

    if(err.status == 403) {
      this.snackBar.open('Você não tem acesso a esse recurso.', 'ok', {
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return EMPTY;
    }

    if(err.status == 500) {
      this.snackBar.open('Houve um erro crítico no servidor. Tente novamente ou entre em contato conosco.', 'ok', {
        panelClass: ['MySnackBar', 'MySnackBar--error'],
      });

      return EMPTY;
    }

    let serverData = err.error as IHttpResponseErrorData;

    let errMsg = serverData.message;
    if(serverData.data?.errors.length > 0) {

      errMsg = '';

      for(let i = 0; i < serverData.data.errors.length; ++i) {
        errMsg += serverData.data.errors[i].message + '\n';
      }
    }

    this.snackBar.open(errMsg, 'ok', {
      panelClass: ['MySnackBar', 'MySnackBar--error'],
    });

    return EMPTY;
  }
}
