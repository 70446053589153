<div class="ValidateSignTokenDialog">
  <button class="ValidateSignTokenDialog-button--close" mat-icon-button color="accent" [mat-dialog-close]="" [disabled]="isLoading">X</button>

  <div *ngIf="isSendToken">
    <h2 mat-dialog-title>Verificação do Token</h2>
    <div mat-dialog-content>
      <p>Informe o token enviado para seu celular / e-mail.</p>

      <form [formGroup]="infoForm" novalidate class="ValidateSignTokenDialog-form">
        <mat-form-field >
          <input matInput formControlName="token" placeholder="Token"  name="token" maxlength="6" [disabled]="this.token != ''">
        </mat-form-field>

        <button mat-raised-button [disabled]="!infoForm.valid || isLoading" color="primary" (click)="sendToken(data.token)">
          Confirmar
        </button>

      </form>


      <p>Após clicar no botão confirmar será assinado o documento, esta ação não pode ser desfeita.</p>
      <p><strong>*O token enviado é valido por 7 dias!</strong></p>
      <p><strong>*Recomendado fazer assinatura eletrônica somente com a presença do cliente na loja! </strong></p>
      <p><strong>*Evite despesas com cancelamentos.</strong></p>

<!--      <p><strong>*O token enviado é válido por 24 horas.</strong></p>-->

    </div>
    <div mat-dialog-actions class="ValidateSignTokenDialog-buttons">
      <app-gear-loading class="ValidateSignTokenDialog-loader" *ngIf="isLoading"></app-gear-loading>

      <!--      <button mat-raised-button color="accent" [mat-dialog-close]="" [disabled]="isLoading">Voltar</button>-->
      <button mat-raised-button color="accent" (click)="reSendToken()">Reenviar token</button>

    </div>
  </div>

  <!--  <div *ngIf="!isSendToken">-->
  <!--    <h2 mat-dialog-title>Reenvio do Token</h2>-->
  <!--    <div mat-dialog-content>-->
  <!--      <p>Informe o celular cadastrado que recebeu o token.</p>-->

  <!--      <form [formGroup]="reSendTokenForm" novalidate class="ValidateSignTokenDialog-form">-->
  <!--        <mat-form-field>-->
  <!--          <input matInput formControlName="cellphone" placeholder="Celular" [imask]="telMask">-->
  <!--        </mat-form-field>-->

  <!--      </form>-->

  <!--      <p><strong>*O token enviado é válido por 24 horas.</strong></p>-->

  <!--    </div>-->
  <!--    <div mat-dialog-actions class="ValidateSignTokenDialog-buttons">-->
  <!--      <app-gear-loading class="ValidateSignTokenDialog-loader" *ngIf="isLoading"></app-gear-loading>-->

  <!--      <button mat-raised-button color="accent" [disabled]="isLoading" (click)="reSendTokenBack()">Voltar</button>-->
  <!--      <button mat-raised-button [disabled]="!reSendTokenForm.valid || isLoading" color="primary" (click)="reSendTokenSubmit()">-->
  <!--        Enviar-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
