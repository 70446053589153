import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomerSignatureInfoValidationComponent} from './customer-signature-info-validation/customer-signature-info-validation.component';
// import {CustomerSignatureContractSignComponent} from './customer-signature-contract-sign/customer-signature-contract-sign.component';
// import {CustomerSignatureDownloadContractComponent} from './customer-signature-download-contract/customer-signature-download-contract.component';
import {CustomerSignatureComponent} from './customer-signature.component';
import {RouterModule} from "@angular/router";
import {HeaderModule} from "../../shared/header/header.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {HttpClientModule} from "@angular/common/http";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {IMaskModule} from "angular-imask";
import {ValidateSignTokenDialogModule} from "../../shared/dialogs/validate-phone-dialog/validate-sign-token-dialog.module";
import { CustomerSignatureFinishedComponent } from './customer-signature-finished/customer-signature-finished.component';
import {GearLoaderModule} from "../../shared/loaders/gear-loader/gear-loader.module";
import {CustomerSignatureContractListComponent} from "./customer-signature-contract-list/customer-signature-contract-list.component";
import {MatListModule} from "@angular/material/list";
import {MatCardModule} from "@angular/material/card";
import { CustomerSignatureDocDetailsComponent } from './customer-signature-doc-details/customer-signature-doc-details.component';
import {LoaderModule} from "../../shared/components/loader/loader.module";
import { MoreInfoBottomSheetComponent } from './customer-signature-doc-details/bottomSheet/more-info-bottom-sheet/more-info-bottom-sheet.component';
import {MatBottomSheet, MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";


@NgModule({
  declarations: [
    CustomerSignatureInfoValidationComponent,
    // CustomerSignatureContractSignComponent,
    // CustomerSignatureDownloadContractComponent,
    CustomerSignatureComponent,
    CustomerSignatureFinishedComponent,
    CustomerSignatureContractListComponent,
    CustomerSignatureDocDetailsComponent,
    MoreInfoBottomSheetComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        HeaderModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatSnackBarModule,
        ReactiveFormsModule,
        HttpClientModule,
        IMaskModule,
        ValidateSignTokenDialogModule,
        GearLoaderModule,
        MatListModule,
        MatCardModule,
        LoaderModule,
        MatBottomSheetModule,
        MatIconModule,
        MatTooltipModule
    ]
})
export class CustomerSignatureModule {
}
