export const CpfMask = {
  mask: '000.000.000-00'
};

export const CnpjMask = {
  mask: '00.000.000/0000-00'
};

export const CpfCnpjMask = {
  mask: [
    {
      mask: '000.000.000-00'
    },
    {
      mask: '00.000.000/0000-00'
    },
  ]
}

export const DateMask = {
  mask: Date,
  min: new Date(1900, 0, 1),
  max: new Date(2050, 0, 1),
  pattern: 'd.m.Y',
};

export const TimeMask = {
  mask: '00:00:00'
}

export const TelMask = {
  mask: [
    {
      mask: '(00) 0000-0000'
    },
    {
      mask: '(00) 0 0000-0000'
    }
  ]
};

export const RgMask = {
  mask: [
    {
      mask: '0.000.000-0'
    },
    {
      mask: '00.000.000-0'
    },
    {
      mask: '000.000.000-0'
    },
    {
      mask: '000.000.000-00'
    }
  ]
};

export const EmailMask = {
  mask: /^\S*@?\S*$/
};

export const CEPMask = {
  mask: '00000-000'
};

export const CurrencyMask = {
  prefix: 'R$ ',
  thousands: '.',
  decimal: ',',
  align: 'left'
};
