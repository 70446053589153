import { Injectable } from '@angular/core';
import {HttpHeaders} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HttpUtilsService {

  constructor(
  ) { }

  public AddBasicHeaders(token?:string): HttpHeaders {
    let header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    if(token) {
      header = header.append('Authorization', `Bearer ${token}`)
    }

    return header;
  }
}
