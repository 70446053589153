import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ValidateSignTokenDialog} from "./validate-sign-token-dialog.component";
import {GearLoaderModule} from "../../loaders/gear-loader/gear-loader.module";
import {IMaskModule} from "angular-imask";



@NgModule({
  declarations: [ValidateSignTokenDialog],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        GearLoaderModule,
        IMaskModule
    ],
  exports: [ValidateSignTokenDialog]
})
export class ValidateSignTokenDialogModule { }
