import {Component, OnDestroy, OnInit} from '@angular/core';
import {IContractListRouteParam} from "../customer-signature-contract-list/definitions";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {HttpUtilsService} from "../../../services/utils/http-utils.service";
import {MyHttpResponseErrorService} from "../../../services/http-response-error/my-http-response-error.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {catchError} from "rxjs/operators";
import {IHttpResponseGeneric} from "../../../shared/interfaces/i-http-response-generic";
import {ICustomerSignatureInfoValidationCpfReturn} from "../definitions";
import {IDocDetailsRouteParam} from "./definitions";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MoreInfoBottomSheetComponent} from "./bottomSheet/more-info-bottom-sheet/more-info-bottom-sheet.component";

@Component({
  selector: 'app-customer-signature-doc-details',
  templateUrl: './customer-signature-doc-details.component.html',
  styleUrls: ['./customer-signature-doc-details.component.scss']
})
export class CustomerSignatureDocDetailsComponent implements OnInit, OnDestroy {

  public isLoading: boolean = false;
  public routeState!: IDocDetailsRouteParam;
  private observableArray: Array<Subscription> = [];

  public guid: string | null = null;
  public key: string | null = null;
  public cpf: string | null = null;
  public uniquekey: string | null = null;

  public pdfStr: SafeUrl | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private myHttpRespErr: MyHttpResponseErrorService,
    private sanitize: DomSanitizer,
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet
  ) {
    this.routeState = this.router.getCurrentNavigation()?.extras.state as IDocDetailsRouteParam;
  }

  ngOnInit(): void {

    this.observableArray.push(
      this.activatedRoute.paramMap.subscribe((value: ParamMap) => {
          this.guid = value.get('guid');
          this.key = value.get('key');
          this.cpf = value.get('cpf');
          this.uniquekey = value.get('uniqueKey');

          if (!this.routeState) {
            this.router.navigate(['/sign-list', this.guid, this.key, this.cpf, this.uniquekey]);

            return;
          }

          this.loadPdf();
        }
      ));
  }

  public ngOnDestroy(): void {
    for (let i = 0; i < this.observableArray.length; ++i) {
      this.observableArray[i].unsubscribe();
    }
  }

  private loadPdf() {

    this.isLoading = true;

    this.observableArray.push(
      this.http.post(`${environment.baseUrl}/Documents/ViewBinary`, this.routeState.docInfo, {
        headers: this.httpUtils.AddBasicHeaders()
      })
        .pipe(
          catchError((err) => this.myHttpRespErr.httpError(err, () => this.isLoading = false))
        )
        .subscribe(resp => {
          this.isLoading = false;

          if (resp === null) {
            this.snackBar.open('Não existem documentos para assinar neste Cpf.', "OK");

            return;
          }

          let data: IHttpResponseGeneric<string> = resp as IHttpResponseGeneric<string>;

          // let pdfSan = this.sanitize.bypassSecurityTrustResourceUrl(data.data);
          const byteCharacters = atob(data.data.split(',')[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: 'application/pdf'});

          this.pdfStr = this.sanitize.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
        })
    );
  }

  moreInfo() {
    this.bottomSheet.open(MoreInfoBottomSheetComponent, {
      data: this.routeState.signersInfo
    });
  }
}
