<!--<div class="Header">-->
<!--  <div class="Header-container Container">-->
<!--    <img src="assets/images/logo.svg" alt="">-->
<!--  </div>-->
<!--</div>-->
<mat-toolbar class="Header">
  <img class="Header-logo" [src]="logoPath" alt="Logo do Cliente">
</mat-toolbar>




