import {ApplicationRef, Component} from '@angular/core';
import {SwUpdate} from "@angular/service-worker";
import {MatSnackBar} from "@angular/material/snack-bar";
import {interval} from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  private onlyOneRegisterToUpdateSW = false;

  constructor(
    private applicationRef: ApplicationRef,
    private updates: SwUpdate,
    private snackBar: MatSnackBar,
  ) {
  }

  ngOnInit(): void {
    this.initServiceWorker();
  }

  initServiceWorker() {
    if (this.updates.isEnabled) {
      // WARNING: If the interval function is called out of this event, the app dont will be stable and the service worker will not function
      this.applicationRef.isStable.subscribe(isStable => {
        if (isStable) {

          if (!this.onlyOneRegisterToUpdateSW) {
            this.onlyOneRegisterToUpdateSW = true;
            // milliseconds * seconds * minutes
            interval(1000 * 60 * 3).subscribe(() => {
              return this.updates.checkForUpdate();
            });

            this.updates.versionUpdates.subscribe(event => {

              this.updates.activateUpdate()
                .then(() => {
                  document.location.reload();
                });

            });

            this.updates.versionUpdates.subscribe(event => {
              this.snackBar.open('Atualizando o App.', '', {
                panelClass: ['MySnackBar', 'MySnackBar--warning'],
                duration: 3500
              });

            });
          }

        }
      });

    }
  }

}
