export const environment = {
  clientName: 'Ucred - Serviços Financeiros',
  production: true,
  baseUrl: 'https://api.alphadocs.release.ucred.net.br',
  //baseUrl: '/api',
  isMobile: 900,
  logoPath: 'assets/images/ucred/logo.png',
  scssPath: 'assets/scss/ucred/theme.scss',
  toolbarColor: '#003366'


};
