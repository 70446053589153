import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpUtilsService} from "../../../services/utils/http-utils.service";
import {MyHttpResponseErrorService} from "../../../services/http-response-error/my-http-response-error.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {catchError, concatMap} from "rxjs/operators";
import {IValidateSignToken} from "./definitions";
import {IHttpResponseGeneric} from "../../interfaces/i-http-response-generic";
import {TelMask} from "../../constants/masks";

@Component({
  selector: 'app-validate-phone-dialog',
  templateUrl: './validate-sign-token-dialog.component.html',
  styleUrls: ['./validate-sign-token-dialog.component.scss']
})
export class ValidateSignTokenDialog implements OnInit {
    public infoForm!: FormGroup;
  public reSendTokenForm!: FormGroup;
  public isLoading: boolean = false;
  public isSendToken: boolean = true;
  public telMask = TelMask;
  public token = ""

  constructor(
    private httpUtils: HttpUtilsService,
    private myHttpRespErr: MyHttpResponseErrorService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private router: Router,
    public dialogRef: MatDialogRef<ValidateSignTokenDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IValidateSignToken) {
  }

  public ngOnInit(): void {
    this.initForm();
    console.log(this.data);

    if(this.data.token != ''){
      this.token = this.data.token;
    }

  }

  public sendToken(token: string) {
    if (!this.infoForm.valid) {
      return;
    }

    try {
      if (!navigator.geolocation) {
        this.snackBar.open('Não é possível prosseguir sem a geolocalização.', 'OK', {});

        return;
      }
      this.isLoading = true;

      navigator.geolocation.getCurrentPosition((location: GeolocationPosition) => {
          this.http.post(`${environment.baseUrl}/Documents/SignDocument`, {
            KeyAuthSign: this.infoForm.get('token')?.value.toUpperCase(),
            DocumentGuid: this.data.guid,
            DocumentKey: this.data.key,
            DocumentSigner: this.data.signerCpf,
            Geolocation: `${location.coords.latitude}, ${location.coords.longitude}`,
            UrlToken: token == '' ? 'N': 'Y'
          }, {
            headers: this.httpUtils.AddBasicHeaders()
          }).pipe(
            catchError(err => this.myHttpRespErr.httpError(err, () => this.isLoading = false)),
          )
            .subscribe((resp: any) => {
              this.isLoading = false;

              let data: IHttpResponseGeneric<null> = resp as IHttpResponseGeneric<null>;
              this.snackBar.open(data.message, 'ok', {
                panelClass: ['MySnackBar', 'MySnackBar--success'],
              });

              this.dialogRef.close();
              this.router.navigate(['/end-sign']);
            });
        }, () => {
          this.isLoading = false;
          this.snackBar.open('Não é possível prosseguir sem a geolocalização.', 'OK', {})
        }
      );

    } catch (e) {
      this.isLoading = false;

      console.error(e);
    }
  }

  private initForm() {
    this.infoForm = this.formBuilder.group({
      token: ['', [Validators.required]]
    });

    // this.reSendTokenForm = this.formBuilder.group({
    //   cellphone: ['', [Validators.required]]
    // });
    this.token = this.data.token;

    if(this.token  != ''){
      this.infoForm.get('token')?.setValue(this.token);

      this.infoForm.get('token')?.updateValueAndValidity();

    }
  }

  reSendToken() {
    // this.isSendToken = false;
    this.reSendTokenSubmit();
  }

  reSendTokenSubmit() {
    try {

      this.isLoading = true;

      this.http.post(`${environment.baseUrl}/Signers/RecoverToken`, {
        DocumentGuid: this.data.guid,
        DocumentKey: this.data.key,
        SignerCpf: this.data.signerCpf,
        // SmsNumber: '+55' + this.reSendTokenForm.get('cellphone')?.value.replace(/[ \-.()]/g, ''),
        DocumentSigner: this.data.signerCpf
      }, {
        headers: this.httpUtils.AddBasicHeaders()
      }).pipe(
        catchError(err => this.myHttpRespErr.httpError(err, () => this.isLoading = false)),
      )
        .subscribe((resp: any) => {
          this.isLoading = false;

          let data: IHttpResponseGeneric<null> = resp as IHttpResponseGeneric<null>;
          this.snackBar.open(data.message, 'ok', {
            panelClass: ['MySnackBar', 'MySnackBar--success'],
          });

          this.isSendToken = true;
        });
    } catch (e) {
      this.isLoading = false;

      console.error(e);
    }
  }

  reSendTokenBack() {
    this.isSendToken = true;
  }
}
