<app-customer-signature>
  <div class="CustomerSignatureInfoValidation">
    <div class="CustomerSignatureInfoValidation-content">
      <p>Para prosseguirmos com o processo de assinatura do seu contrato de <strong>financiamento</strong>, <br> solicitamos
        que informe o seu CPF.</p>

      <form class="CustomerSignatureInfoValidation-form" [formGroup]="validationForm" novalidate
            (submit)="submitForm()">
        <mat-form-field>
          <input [imask]="cpfMask" type="tel" matInput formControlName="Cpf" placeholder="CPF">
        </mat-form-field>

        <button class="CustomerSignatureInfoValidation-button--submit" type="submit"
                [disabled]="!validationForm.valid || isLoading" mat-raised-button color="accent">
          VALIDAR
        </button>

        <app-gear-loading *ngIf="isLoading"></app-gear-loading>

      </form>
    </div>
  </div>
</app-customer-signature>
